/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
'use strict';

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  onSnapshot,
  setDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getPerformance } from 'firebase/performance';

import { getFirebaseConfig } from './firebase-config.js';

import {subscriberByEmail} from './js/subscriber-api.js';



var sign_type=0

//通過郵件登錄
async function signUpByEmail() {
  alert('TODO: Implement Email Sign-In');
  // TODO 1: Sign in Firebase with credential from the Google user.
  // var provider = new EmailAuthProvider();
  // await signInWithPopup(getAuth(), provider);
  var email=document.getElementById('sign_email').value;
  var password=document.getElementById('sign_password').value;
  const auth = getAuth();
  createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // document.location.href="/";
    // 登錄進去
    if(user){
      signInButtonElement.setAttribute('hidden', 'true');
      signOutButtonElement.removeAttribute('hidden');
      // Hide sign-in button.
      signInElement.setAttribute('hidden', 'true');
      signInElement.style.cssText='display:none';
    
    }else{
      //登出
    }
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });

  sign_type=1;

}

//通過郵件登錄
async function signInByEmail() {
  alert('TODO: Implement Email Sign-In');
  // TODO 1: Sign in Firebase with credential from the Google user.
  // var provider = new EmailAuthProvider();
  // await signInWithPopup(getAuth(), provider);
  var email=document.getElementById('sign_email').value;
  var password=document.getElementById('sign_password').value;
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // document.location.href="/";
    // 登錄進去
    if(user){
      signInButtonElement.setAttribute('hidden', 'true');
      signOutButtonElement.removeAttribute('hidden');
      // Hide sign-in button.
      signInElement.setAttribute('hidden', 'true');
      signInElement.style.cssText='display:none';
    
    }else{
      //登出
    }
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });

  sign_type=1;

}

//通過google登錄
async function signInByGoogle() {
  // alert('TODO: Implement Google Sign-In');
  // TODO 1: Sign in Firebase with credential from the Google user.
  var provider = new GoogleAuthProvider();
  await signInWithPopup(getAuth(), provider);
  document.location.href="/";
}

//通過facebook登錄
async function signInByFacebook() {
  alert('TODO: Implement Facebook Sign-In');
  // TODO 1: Sign in Firebase with credential from the Google user.
  var provider = new FacebookAuthProvider();
  await signInWithPopup(getAuth(), provider);
  document.location.href="/";
}

//通過推特登錄
async function signInByTwitter() {
  alert('TODO: Implement Twitter Sign-In');
  // TODO 1: Sign in Firebase with credential from the Google user.
  var provider = new TwitterAuthProvider();
  await signInWithPopup(getAuth(), provider);
  document.location.href="/";
}

// Signs-out of Friendly Chat.
export function signOutUser() {
  // TODO 2: Sign out of Firebase.
  signOut(getAuth());
}

// Initiate firebase auth
export function initFirebaseAuth() {
  // TODO 3: Subscribe to the user's signed-in status
  onAuthStateChanged(getAuth(), authStateObserver);
}

// Returns the signed-in user's profile Pic URL.
function getProfilePicUrl() {
  // TODO 4: Return the user's profile pic URL.
  return getAuth().currentUser.photoURL || '/images/profile_placeholder.png';
}

// Returns the signed-in user's display name.
function getUserName() {
  // TODO 5: Return the user's display name.
  return getAuth().currentUser.displayName;
}

// Returns true if a user is signed-in.
function isUserSignedIn() {
  // TODO 6: Return true if a user is signed-in.
  return !!getAuth().currentUser;
}

// Saves a new message on the Cloud Firestore.
async function saveMessage(messageText) {
  // TODO 7: Push a new message to Cloud Firestore.
  try {
    await addDoc(collection(getFirestore(), 'messages'), {
      name: getUserName(),
      text: messageText,
      profilePicUrl: getProfilePicUrl(),
      timestamp: serverTimestamp()
    });
  }
  catch(error) {
    console.error('Error writing new message to Firebase Database', error);
  }
}

// Loads chat messages history and listens for upcoming ones.
export function loadMessages() {
  // TODO 8: Load and listen for new messages.
  const recentMessagesQuery = query(collection(getFirestore(), 'messages'), orderBy('timestamp', 'desc'), limit(12));
  
  // Start listening to the query.
  onSnapshot(recentMessagesQuery, function(snapshot) {
    snapshot.docChanges().forEach(function(change) {
      if (change.type === 'removed') {
        deleteMessage(change.doc.id);
      } else {
        var message = change.doc.data();
        displayMessage(change.doc.id, message.timestamp, message.name,
                      message.text, message.profilePicUrl, message.imageUrl);
      }
    });
  });
}

// Saves a new message containing an image in Firebase.
// This first saves the image in Firebase storage.
async function saveImageMessage(file) {
  // TODO 9: Posts a new image as a message.
  try {
    // 1 - We add a message with a loading icon that will get updated with the shared image.
    const messageRef = await addDoc(collection(getFirestore(), 'messages'), {
      name: getUserName(),
      imageUrl: LOADING_IMAGE_URL,
      profilePicUrl: getProfilePicUrl(),
      timestamp: serverTimestamp()
    });

    // 2 - Upload the image to Cloud Storage.
    const filePath = `${getAuth().currentUser.uid}/${messageRef.id}/${file.name}`;
    const newImageRef = ref(getStorage(), filePath);
    const fileSnapshot = await uploadBytesResumable(newImageRef, file);
    
    // 3 - Generate a public URL for the file.
    const publicImageUrl = await getDownloadURL(newImageRef);

    // 4 - Update the chat message placeholder with the image's URL.
    await updateDoc(messageRef,{
      imageUrl: publicImageUrl,
      storageUri: fileSnapshot.metadata.fullPath
    });
  } catch (error) {
    console.error('There was an error uploading a file to Cloud Storage:', error);
  }
}

// Saves the messaging device token to Cloud Firestore.
async function saveMessagingDeviceToken() {
  // TODO 10: Save the device token in Cloud Firestore
  try {
    const currentToken = await getToken(getMessaging());
    if (currentToken) {
      console.log('Got FCM device token:', currentToken);
      // Saving the Device Token to Cloud Firestore.
      const tokenRef = doc(getFirestore(), 'fcmTokens', currentToken);
      await setDoc(tokenRef, { uid: getAuth().currentUser.uid });

      // This will fire when a message is received while the app is in the foreground.
      // When the app is in the background, firebase-messaging-sw.js will receive the message instead.
      onMessage(getMessaging(), (message) => {
        console.log(
          'New foreground notification from Firebase Messaging!',
          message.notification
        );
      });
    } else {
      // Need to request permissions to show notifications.
      requestNotificationsPermissions();
    }
  } catch(error) {
    console.error('Unable to get messaging token.', error);
  };
}

// Requests permissions to show notifications.
async function requestNotificationsPermissions() {
  // TODO 11: Request permissions to send notifications.
  console.log('Requesting notifications permission...');
  const permission = await Notification.requestPermission();
  
  if (permission === 'granted') {
    console.log('Notification permission granted.');
    // Notification permission granted.
    await saveMessagingDeviceToken();
  } else {
    console.log('Unable to get permission to notify.');
  }
}

// Triggered when a file is selected via the media picker.
function onMediaFileSelected(event) {
  event.preventDefault();
  var file = event.target.files[0];

  // Clear the selection in the file picker input.
  imageFormElement.reset();

  // Check if the file is an image.
  if (!file.type.match('image.*')) {
    var data = {
      message: 'You can only share images',
      timeout: 2000,
    };
    signInSnackbarElement.MaterialSnackbar.showSnackbar(data);
    return;
  }
  // Check if the user is signed-in
  if (checkSignedInWithMessage()) {
    saveImageMessage(file);
  }
}

// Triggered when the send new message form is submitted.
function onMessageFormSubmit(e) {
  e.preventDefault();
  // Check that the user entered a message and is signed in.
  if (messageInputElement.value && checkSignedInWithMessage()) {
    saveMessage(messageInputElement.value).then(function () {
      // Clear message text field and re-enable the SEND button.
      resetMaterialTextfield(messageInputElement);
      toggleButton();
    });
  }
}

//
//檢測登錄狀態，並顯示/隱藏 登錄/注冊 按鈕
function authStateObserver(user) {
  if (user) {
    
    signInButtonElement.setAttribute('hidden', 'true');
    signOutButtonElement.removeAttribute('hidden');
    // Hide sign-in button.
    // signInElement.setAttribute('hidden', 'true');
    // signInElement.style.cssText='display:none';

    // User is signed in!
    // Get the signed-in user's profile pic and name.
    var profilePicUrl = getProfilePicUrl();
    var userName = getUserName();

    // Set the user's profile pic and name.
    userPicElement.style.backgroundImage =
      'url(' + addSizeToGoogleProfilePic(profilePicUrl) + ')';
    userNameElement.textContent = userName;

    // Show user's profile and sign-out button.
    userNameElement.removeAttribute('hidden');
    userPicElement.removeAttribute('hidden');

    // We save the Firebase Messaging Device token and enable notifications.
    saveMessagingDeviceToken();

    //todo 是否登錄
    if(document.getElementById("yourLoginState")) {
      document.getElementById('yourLoginState').value="1"
    }
    

    //檢測該用戶是否是訂閱者，訂閲是否過期，並顯示可用資源點
    var tagStatus=subscriberByEmail(user.email);
    if(tagStatus==1){
      console.log('tagStatus is 1')
    }else if(tagStatus==0){
      //todo
      alert('You have not subscribed yet, please subscribe first')
    }else{
      //訂閲是否過期
      // alert('You have already subscribed, please start using our AI service!')
      
      
    }
  } else {

    signOutButtonElement.setAttribute('hidden', 'true');
    signInButtonElement.setAttribute('hidden', 'true');
    // 顯示 登錄/注冊 按鈕.
    // signInElement.setAttribute('hidden', 'false');
    // signInElement.style.cssText='';
    //顯示谷歌登錄按鈕
    googleSignInButtonElement.removeAttribute('hidden');

    // Hide user's profile and sign-out button.
    userNameElement.setAttribute('hidden', 'true');
    userPicElement.setAttribute('hidden', 'true');

    //todo 是否登錄
    if(document.getElementById("yourLoginState")) {
      document.getElementById('yourLoginState').value="0"
    }
    
  }
}

// Returns true if user is signed-in. Otherwise false and displays a message.
function checkSignedInWithMessage() {
  // Return true if the user is signed in Firebase
  if (isUserSignedIn()) {
    return true;
  }

  // Display a message to the user using a Toast.
  var data = {
    message: 'You must sign-in first',
    timeout: 2000,
  };
  signInSnackbarElement.MaterialSnackbar.showSnackbar(data);
  return false;
}

// Resets the given MaterialTextField.
function resetMaterialTextfield(element) {
  element.value = '';
  element.parentNode.MaterialTextfield.boundUpdateClassesHandler();
}

// Template for messages.
var MESSAGE_TEMPLATE =
  '<div class="message-container">' +
  '<div class="spacing"><div class="pic"></div></div>' +
  '<div class="message"></div>' +
  '<div class="name"></div>' +
  '</div>';

// Adds a size to Google Profile pics URLs.
function addSizeToGoogleProfilePic(url) {
  if (url.indexOf('googleusercontent.com') !== -1 && url.indexOf('?') === -1) {
    return url + '?sz=150';
  }
  return url;
}

// A loading image URL.
var LOADING_IMAGE_URL = 'https://www.google.com/images/spin-32.gif?a';

// Delete a Message from the UI.
function deleteMessage(id) {
  var div = document.getElementById(id);
  // If an element for that message exists we delete it.
  if (div) {
    div.parentNode.removeChild(div);
  }
}

function createAndInsertMessage(id, timestamp) {
  const container = document.createElement('div');
  container.innerHTML = MESSAGE_TEMPLATE;
  const div = container.firstChild;
  div.setAttribute('id', id);

  // If timestamp is null, assume we've gotten a brand new message.
  // https://stackoverflow.com/a/47781432/4816918
  timestamp = timestamp ? timestamp.toMillis() : Date.now();
  div.setAttribute('timestamp', timestamp);

  // figure out where to insert new message
  const existingMessages = messageListElement.children;
  if (existingMessages.length === 0) {
    messageListElement.appendChild(div);
  } else {
    let messageListNode = existingMessages[0];

    while (messageListNode) {
      const messageListNodeTime = messageListNode.getAttribute('timestamp');

      if (!messageListNodeTime) {
        throw new Error(
          `Child ${messageListNode.id} has no 'timestamp' attribute`
        );
      }

      if (messageListNodeTime > timestamp) {
        break;
      }

      messageListNode = messageListNode.nextSibling;
    }

    messageListElement.insertBefore(div, messageListNode);
  }

  return div;
}

// Displays a Message in the UI.
function displayMessage(id, timestamp, name, text, picUrl, imageUrl) {
  var div =
    document.getElementById(id) || createAndInsertMessage(id, timestamp);

  // profile picture
  if (picUrl) {
    div.querySelector('.pic').style.backgroundImage =
      'url(' + addSizeToGoogleProfilePic(picUrl) + ')';
  }

  div.querySelector('.name').textContent = name;
  var messageElement = div.querySelector('.message');

  if (text) {
    // If the message is text.
    messageElement.textContent = text;
    // Replace all line breaks by <br>.
    messageElement.innerHTML = messageElement.innerHTML.replace(/\n/g, '<br>');
  } else if (imageUrl) {
    // If the message is an image.
    var image = document.createElement('img');
    image.addEventListener('load', function () {
      messageListElement.scrollTop = messageListElement.scrollHeight;
    });
    image.src = imageUrl + '&' + new Date().getTime();
    messageElement.innerHTML = '';
    messageElement.appendChild(image);
  }
  // Show the card fading-in and scroll to view the new message.
  setTimeout(function () {
    div.classList.add('visible');
  }, 1);
  messageListElement.scrollTop = messageListElement.scrollHeight;
  messageInputElement.focus();
}

// Enables or disables the submit button depending on the values of the input
// fields.
function toggleButton() {
  if (messageInputElement.value) {
    submitButtonElement.removeAttribute('disabled');
  } else {
    submitButtonElement.setAttribute('disabled', 'true');
  }
}


// Shortcuts to DOM Elements.
var messageListElement = document.getElementById('messages');
var messageFormElement = document.getElementById('message-form');
var messageInputElement = document.getElementById('message');
var submitButtonElement = document.getElementById('submit');
var imageButtonElement = document.getElementById('submitImage');
var imageFormElement = document.getElementById('image-form');
var mediaCaptureElement = document.getElementById('mediaCapture');
var userPicElement = document.getElementById('user-pic');
var userNameElement = document.getElementById('user-name');
var signInButtonElement = document.getElementById('sign-in');
var signOutButtonElement = document.getElementById('sign-out');
var signInSnackbarElement = document.getElementById('must-signin-snackbar');

//新增 2022.11.08
// var signInElement = document.getElementById('sign-in-container');

//暫時屏蔽
// Saves message on form submit.
// messageFormElement.addEventListener('submit', onMessageFormSubmit);
signOutButtonElement.addEventListener('click', signOutUser);
//signInButtonElement.addEventListener('click', signIn);

// Toggle for the button.
// messageInputElement.addEventListener('keyup', toggleButton);
// messageInputElement.addEventListener('change', toggleButton);

// Events for image upload.
// imageButtonElement.addEventListener('click', function (e) {
//   e.preventDefault();
//   mediaCaptureElement.click();
// });
// mediaCaptureElement.addEventListener('change', onMediaFileSelected);

//初始化
// const firebaseAppConfig = getFirebaseConfig();
// // TODO 0: Initialize Firebase
// initializeApp(firebaseAppConfig);
// // TODO 12: Initialize Firebase Performance Monitoring
//
// initFirebaseAuth();
// loadMessages();
// // TODO: Enable Firebase Performance Monitoring.
// getPerformance();

// //郵件-注冊
// var emailSignUpButtonElement = document.getElementById('email_signup');
// emailSignUpButtonElement.addEventListener('click', signUpByEmail);
// //郵件-登錄
// var emailSignInButtonElement = document.getElementById('email_sign');
// emailSignInButtonElement.addEventListener('click', signInByEmail);

//google sign********************
var googleSignInButtonElement = document.getElementById('sign-in');
googleSignInButtonElement.addEventListener('click', signInByGoogle);


// //facebook登錄
// var facebookSignInButtonElement = document.getElementById('facebook_sign');
// facebookSignInButtonElement.addEventListener('click', signInByFacebook);
//
// //推特登錄
// var twitterSignInButtonElement = document.getElementById('twitter_sign');
// twitterSignInButtonElement.addEventListener('click', signInByTwitter);