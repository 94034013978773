/**
 * To find your Firebase config object:
 * 
 * 1. Go to your [Project settings in the Firebase console](https://console.firebase.google.com/project/_/settings/general/)
 * 2. In the "Your apps" card, select the nickname of the app for which you need a config object.
 * 3. Select Config from the Firebase SDK snippet pane.
 * 4. Copy the config object snippet, then add it here.
 */


 const config = {

  apiKey: "AIzaSyD7lbbUfr1Py0hJYAibZHlRCwu2oNoDztY",
  authDomain: "gpt4-article.firebaseapp.com",
  projectId: "gpt4-article",
  storageBucket: "gpt4-article.appspot.com",
  messagingSenderId: "830102824575",
  appId: "1:830102824575:web:3666f0ab32ec350eb5814a",
  measurementId: "G-K3KZ1JK3KF"
};


export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error('No Firebase configuration object provided.' + '\n' +
    'Add your web app\'s configuration object to firebase-config.js');
  } else {
    return config;
  }
}

// module.exports={getFirebaseConfig}


