export async function subscriberList(){
    var result = {};
    var url1 = "https://api.convertkit.com/v3/subscribers?api_secret=yJrvzlQ-Uk6-wo8JOTPxIv0f_cdNEZBMqQCcVDgWgAc";
    // var url2 = "https://lexica.art/api/v1/search?q="+imageUrl;
    var headers = {}
    result = (fetch(url1, {
        method : "GET",
        mode: 'cors',
        headers: headers
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.error)
          }
          return response.json();
        })
        .then(data => {
          
          return data;
        })
        .catch(function(error) {
          console.error('subscribersList():請求錯誤1,error:'+error.message)
        }))
    return result;
}

export async function subscriberByEmail(email){
    var subscribeResult = {};
    var url1 = "https://api.convertkit.com/v3/subscribers?api_secret=yJrvzlQ-Uk6-wo8JOTPxIv0f_cdNEZBMqQCcVDgWgAc&email_address="+email;
    // var url2 = "https://lexica.art/api/v1/search?q="+imageUrl;
    const headers = {}
    var subscriberId=0;
    subscribeResult = (await fetch(url1, {
        method : "GET",
        mode: 'cors',
        headers: headers
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.error)
          }
          return response.json();
        })
        .then(data => {
          
          return data;
        })
        .catch(function(error) {
          console.error('subscriberByEmail():請求錯誤1,error:'+error.message)
        }))
        console.log('subscribe list:')
        console.log(subscribeResult)
        //檢測該用戶是否是訂閱者，訂閲是否過期，並顯示可用資源點
        var subscribers = [];
        subscribers = subscribeResult.subscribers;
        if(subscribeResult.total_subscribers==0){
          //todo
          console.log('user-setting-page: Please subscribe to continue using!')
          //todo 是否訂閲
          if(document.getElementById("yourSubscribeState")) {
            document.getElementById('yourSubscribeState').value="0"  
          }
          
        }else{
          var subscribeStatus=false;
          for(var i=0;i<subscribers.length;i++){
            var obj_i=subscribers[i];
            
            if(obj_i.state=="active"){
              subscriberId=obj_i.id;
              subscribeStatus=true;
            }
            
          }
          //todo 是否訂閲
          var tagStatus=0;
          var productName='gpt4-article';
          var productTagId=3525768;             
          if(document.getElementById("user-setting-page")||document.getElementById("index-page")) {
            if(subscribeStatus){
              
              //todo 2
              const url2="https://api.convertkit.com/v3/subscribers/"+subscriberId+"/tags?api_key=zHQOrAFeP297mBDucnQg_Q";
              var resultCode=0;
              const response2 = (await fetch(url2, {
                method : "GET",
                mode: 'cors',
                headers: headers
              })
              .then((response) => {
                if (!response.ok) {
                  throw new Error(response.error)
                }
                return response.json();
              })
              .then(data => {
                
                return data;
              })
              .catch(function(error) {
                console.error('subscriberByEmail():請求錯誤2,error:'+error.message)
              }))
              console.log('response2:')
              console.log(response2)
              var tags=response2.tags;
              if(tags==undefined){
                console.log('tags==undefined')
                return 'code:1024,msg:sorry,something wrong';
              }
              if(tags.length==0){
                console.log('not subscribe, subscriber have not tags')
                return 0;
              }
              
              for(var i=0;i<tags.length;i++){
                var tagObj=tags[i];
                if(productTagId==tagObj.id){
                  tagStatus=1;
                  break;
                }
              }
              //
            }
            if(tagStatus==1){
              document.getElementById('yourSubscribeState').value="1"
              //
            }else{
              document.getElementById('yourSubscribeState').value="0"
            }
            
          }  
          if(document.getElementById("user-setting-page")) {
            //顯示訂閲項
            if(tagStatus==1){
              var yourSubscribeTableHtml = ''
              for(var i=0;i<subscribers.length;i++){
                var obj_i=subscribers[i];
                //
                yourSubscribeTableHtml = yourSubscribeTableHtml + '<tr>'
                yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><div class="lead"><div class="lead-text"><p>'
                yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.first_name+'</p></div></div></td>'
                //
                yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
                yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.email_address+'</a></p></td>'
                //
                yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p><a href="#0">'
                yourSubscribeTableHtml = yourSubscribeTableHtml + productName +'</a></p></td>'
                //
                yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
                yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.state+'</p></td>'
                //
                yourSubscribeTableHtml = yourSubscribeTableHtml + '<td class="min-width"><p>'
                yourSubscribeTableHtml = yourSubscribeTableHtml + obj_i.created_at+'</p></td>'
                
              }
              yourSubscribeTableHtml = yourSubscribeTableHtml + '</tr>'
              //
              var yourSubscribeTableEle = document.getElementById('yourSubscribeTable')
              yourSubscribeTableEle.innerHTML=yourSubscribeTableHtml;
            }
            
            
          }
          
        }
    return tagStatus;
}

export async function subscriberByEmail2(email){
  var result = {};
  var url1 = "https://api.convertkit.com/v3/subscribers?api_secret=yJrvzlQ-Uk6-wo8JOTPxIv0f_cdNEZBMqQCcVDgWgAc&email="+email;
  // var url2 = "https://lexica.art/api/v1/search?q="+imageUrl;
  const headers = {
    "Content-Type": "application/json"
  }
  result = await fetch(url1, {
      method : "GET",
      mode: 'cors',
      headers: headers
    })
      console.log('subscribe list2:')
        console.log(result)
  return result;
}

